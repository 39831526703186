.custom-hamburger {
    /* Configuration Parameters */
    $lineWidth: 5px;
    $lineLength: 25px;
    $baseMargin: 8px;

    // To use mixins - @include (set value here);
    // i.e. - @include color(red);
    @mixin color($color) {
        color: $color;
    }

    @mixin rotate($rotateDegrees) {
        -webkit-transform: $rotateDegrees;
        -moz-transform: $rotateDegrees;
        -ms-transform: $rotateDegrees;
        -o-transform: $rotateDegrees;
        transform: $rotateDegrees;
    }

    @mixin animation-duration($animationDuration) {
        -webkit-transition-duration: $animationDuration;
        -moz-transition-duration: $animationDuration;
        -ms-transition-duration: $animationDuration;
        -o-transition-duration: $animationDuration;
        transition-duration: $animationDuration;
    }

    @mixin animation-delay($animationDelay) {
        -webkit-transition-delay: $animationDelay;
        -moz-transition-delay: $animationDelay;
        -ms-transition-delay: $animationDelay;
        -o-transition-delay: $animationDelay;
        transition-delay: $animationDelay;
    }

    @keyframes fade-opacity-in {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    // .nav {
    //     max-height: 0;
    //     overflow: hidden;
    //     margin-top: 43px;
    //     box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
    // }

    // ul {
    //     list-style-type: none;
    //     padding: 0;
    //     margin: 0;
    // }

    #hamburger-checkbox {
        display: none;
    }

    .hamburger-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        display: block;
        cursor: pointer;
        height: $baseMargin * 2 + $lineWidth;
        width: $lineLength;

        span {
            display: block;
            top: $baseMargin;
            width: $lineLength;
            height: $lineWidth;
            position: relative;
            @include animation-duration(0.2s);
            @include animation-delay(0s);

            &:after,
            &:before {
                display: block;
                content: "";
                position: absolute;
                width: $lineLength;
                height: $lineWidth;
                @include animation-duration(0.2s);
                @include animation-delay(0s);
            }
            &:before {
                margin-top: -$baseMargin;
            }
            &:after {
                margin-top: $baseMargin;
            }
        }
    }

    #hamburger-checkbox:checked ~ .nav {
        width: 300px;
        max-height: 99999px;
        -webkit-transition: max-height 2s;
    }

    #hamburger-checkbox:checked ~ .hamburger-icon {
        span {
            background-color: rgba(0, 0, 0, 0.1);
            @include animation-duration(0.2s);

            &:before,
            &:after {
                margin-top: 0px;
                @include animation-duration(0.2s);
            }
            &:before {
                @include rotate(rotate(45deg));
            }
            &:after {
                @include rotate(rotate(-45deg));
            }
        }
    }

    .nav a,
    .nav label {
        color: #8e8e93;
        display: block;
        padding: 0.85rem;
        box-shadow: inset 0 -1px #e6e6e6;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
    }

    .nav a:focus,
    .nav a:hover,
    .nav label:focus,
    .nav label:hover {
        // color: rgba(255, 255, 255, 0.5);
        // background: #030303;
    }

    .nav label {
        cursor: pointer;
    }

    /**
 * Styling first level lists items
 */

    .group-list a,
    .group-list label {
        padding-left: 2rem;
        // background: #252525;
        box-shadow: inset 0 -1px #e6e6e6;
    }

    .group-list a:focus,
    .group-list a:hover,
    .group-list label:focus,
    .group-list label:hover {
        background: #e6e6e6;
    }

    /**
 * Styling second level list items
 */

    .sub-group-list a,
    .sub-group-list label {
        padding-left: 4rem;
        box-shadow: inset 0 -1px #e6e6e6;
        color: #e6e6e6;
    }

    .sub-group-list a:focus,
    .sub-group-list a:hover,
    .sub-group-list label:focus,
    .sub-group-list label:hover {
        background: #e6e6e6;
    }

    /**
 * Styling third level list items
 */

    .sub-sub-group-list a,
    .sub-sub-group-list label {
        padding-left: 6rem;
        background: #e6e6e6;
        box-shadow: inset 0 -1px #e6e6e6;
    }

    .sub-sub-group-list a:focus,
    .sub-sub-group-list a:hover,
    .sub-sub-group-list label:focus,
    .sub-sub-group-list label:hover {
        background: #8e8e93;
    }

    /**
 * Hide nested lists
 */

    .group-list,
    .sub-group-list,
    .sub-sub-group-list {
        height: 100%;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 0.2s ease-in-out;
    }

    .nav__list input[type="checkbox"]:checked + label + ul {
        /* reset the height when checkbox is checked */
        max-height: 1000px;
    }

    label > span {
        float: right;
        -webkit-transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
    }

    .nav__list input[type="checkbox"]:checked + label > span {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
/*
 * Just a quick hamburger
 */
.header {
    background-color: #fff;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    // position: fixed;
    width: 100%;
    z-index: 3;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;

    .active {
        background-color: #e6e6e6;
    }
}

.header a li span {
    display: block !important;
    padding: 15px 20px !important;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}

.header .logo {
    display: block;
    float: left;
    font-size: 1.5em;
    padding: 10px 20px;
    text-decoration: none;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

/* menu icon */
.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 1240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}
/** END Hamburger Icon **/

.custom-nav {
    .rs-btn {
        font-size: unset !important;
        padding: 10px 32px 10px 12px;
    }

    // min-height: 40px;
    // margin-top: 40px;
    // padding-top: 20px;
    position: relative;
    background-color: #3f5972;
    color: #fff;

    .rs-nav .rs-dropdown > .rs-dropdown-toggle {
        height: unset;
    }

    .rs-nav-tabs.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {
        padding: 10px 20px;
        outline: unset;
    }

    .rs-nav-item {
        span {
            color: #fff !important;
            font-size: 18px;
        }
    }

    li {
        font-size: 18px;
        line-height: 1.42857143;
        cursor: pointer;
        transition: color 0.3s linear, background-color 0.3s linear;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            border-radius: 6px 6px 0 0;
        }
    }

    a {
        color: unset;
    }

    li > span {
        color: #fafafa;
    }

    .active {
        li > span {
            background: #fafafa;
            color: #344b60 !important;
        }
    }

    li {
        .active {
            li > span {
                background: #5aa4d9;
                color: #fff !important;
            }
        }
    }
}
// Small devices (landscape phones, 576px and up)
@media (max-width: 699px) {
    .custom-nav {
        display: none;
    }

    .hamburger-icon {
        .container {
            display: inline-block;
            cursor: pointer;
        }
    }
}
@media (min-width: 700px) {
    .hamburger-icon {
        .container {
            display: none;
        }
    }
}



/** Event detail **/
.nav__container {
    margin-bottom: 5px;
  
    .banner {
      position: relative;
      background-color: #3f5972;
      // height: 35vh;
      color: #fff;
      padding: 45px;
    }
  
    @keyframes animate {
      0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
      100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
      }
    }
  
    .background {
      width: 100vw;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      background: #233a5f;
      overflow: hidden;
    }
    .background li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animate 35s linear infinite;
    }
  
    .background li:nth-child(0) {
      left: 74%;
      width: 21px;
      height: 21px;
      bottom: -21px;
      animation-delay: 1s;
    }
    .background li:nth-child(1) {
      left: 52%;
      width: 19px;
      height: 19px;
      bottom: -19px;
      animation-delay: 1s;
    }
    .background li:nth-child(2) {
      left: 85%;
      width: 14px;
      height: 14px;
      bottom: -14px;
      animation-delay: 1s;
    }
    .background li:nth-child(3) {
      left: 19%;
      width: 3px;
      height: 3px;
      bottom: -3px;
      animation-delay: 7s;
    }
    .background li:nth-child(4) {
      left: 24%;
      width: 14px;
      height: 14px;
      bottom: -14px;
      animation-delay: 15s;
    }
    .background li:nth-child(5) {
      left: 0%;
      width: 12px;
      height: 12px;
      bottom: -12px;
      animation-delay: 13s;
    }
    .background li:nth-child(6) {
      left: 54%;
      width: 14px;
      height: 14px;
      bottom: -14px;
      animation-delay: 16s;
    }
    .background li:nth-child(7) {
      left: 62%;
      width: 11px;
      height: 11px;
      bottom: -11px;
      animation-delay: 30s;
    }
    .background li:nth-child(8) {
      left: 25%;
      width: 7px;
      height: 7px;
      bottom: -7px;
      animation-delay: 31s;
    }
    .background li:nth-child(9) {
      left: 0%;
      width: 1px;
      height: 1px;
      bottom: -1px;
      animation-delay: 34s;
    }
    .background li:nth-child(10) {
      left: 82%;
      width: 14px;
      height: 14px;
      bottom: -14px;
      animation-delay: 35s;
    }
    .background li:nth-child(11) {
      left: 29%;
      width: 24px;
      height: 24px;
      bottom: -24px;
      animation-delay: 27s;
    }
    .background li:nth-child(12) {
      left: 37%;
      width: 20px;
      height: 20px;
      bottom: -20px;
      animation-delay: 58s;
    }
    .background li:nth-child(13) {
      left: 79%;
      width: 28px;
      height: 28px;
      bottom: -28px;
      animation-delay: 21s;
    }
    .background li:nth-child(14) {
      left: 37%;
      width: 28px;
      height: 28px;
      bottom: -28px;
      animation-delay: 44s;
    }
    .background li:nth-child(15) {
      left: 36%;
      width: 4px;
      height: 4px;
      bottom: -4px;
      animation-delay: 10s;
    }
    .background li:nth-child(16) {
      left: 68%;
      width: 10px;
      height: 10px;
      bottom: -10px;
      animation-delay: 21s;
    }
    .background li:nth-child(17) {
      left: 60%;
      width: 18px;
      height: 18px;
      bottom: -18px;
      animation-delay: 44s;
    }
    .background li:nth-child(18) {
      left: 43%;
      width: 9px;
      height: 9px;
      bottom: -9px;
      animation-delay: 84s;
    }
    .background li:nth-child(19) {
      left: 48%;
      width: 2px;
      height: 2px;
      bottom: -2px;
      animation-delay: 32s;
    }
    .background li:nth-child(20) {
      left: 20%;
      width: 9px;
      height: 9px;
      bottom: -9px;
      animation-delay: 22s;
    }
    .background li:nth-child(21) {
      left: 45%;
      width: 10px;
      height: 10px;
      bottom: -10px;
      animation-delay: 6s;
    }
    .background li:nth-child(22) {
      left: 79%;
      width: 18px;
      height: 18px;
      bottom: -18px;
      animation-delay: 60s;
    }
    .background li:nth-child(23) {
      left: 27%;
      width: 19px;
      height: 19px;
      bottom: -19px;
      animation-delay: 4s;
    }
    .background li:nth-child(24) {
      left: 9%;
      width: 29px;
      height: 29px;
      bottom: -29px;
      animation-delay: 39s;
    }
    .background li:nth-child(25) {
      left: 16%;
      width: 4px;
      height: 4px;
      bottom: -4px;
      animation-delay: 79s;
    }
    .background li:nth-child(26) {
      left: 21%;
      width: 17px;
      height: 17px;
      bottom: -17px;
      animation-delay: 122s;
    }
    .background li:nth-child(27) {
      left: 80%;
      width: 10px;
      height: 10px;
      bottom: -10px;
      animation-delay: 11s;
    }
    .background li:nth-child(28) {
      left: 10%;
      width: 27px;
      height: 27px;
      bottom: -27px;
      animation-delay: 76s;
    }
    .background li:nth-child(29) {
      left: 47%;
      width: 2px;
      height: 2px;
      bottom: -2px;
      animation-delay: 92s;
    }
    .background li:nth-child(30) {
      left: 33%;
      width: 20px;
      height: 20px;
      bottom: -20px;
      animation-delay: 55s;
    }
    .background li:nth-child(31) {
      left: 65%;
      width: 22px;
      height: 22px;
      bottom: -22px;
      animation-delay: 153s;
    }
    .background li:nth-child(32) {
      left: 32%;
      width: 16px;
      height: 16px;
      bottom: -16px;
      animation-delay: 64s;
    }
    .background li:nth-child(33) {
      left: 40%;
      width: 29px;
      height: 29px;
      bottom: -29px;
      animation-delay: 108s;
    }
    .background li:nth-child(34) {
      left: 30%;
      width: 14px;
      height: 14px;
      bottom: -14px;
      animation-delay: 149s;
    }
    .background li:nth-child(35) {
      left: 66%;
      width: 29px;
      height: 29px;
      bottom: -29px;
      animation-delay: 91s;
    }
    .background li:nth-child(36) {
      left: 75%;
      width: 14px;
      height: 14px;
      bottom: -14px;
      animation-delay: 100s;
    }
    .background li:nth-child(37) {
      left: 41%;
      width: 28px;
      height: 28px;
      bottom: -28px;
      animation-delay: 149s;
    }
    .background li:nth-child(38) {
      left: 21%;
      width: 4px;
      height: 4px;
      bottom: -4px;
      animation-delay: 168s;
    }
    .background li:nth-child(39) {
      left: 73%;
      width: 4px;
      height: 4px;
      bottom: -4px;
      animation-delay: 64s;
    }
    .background li:nth-child(40) {
      left: 80%;
      width: 26px;
      height: 26px;
      bottom: -26px;
      animation-delay: 99s;
    }
    .background li:nth-child(41) {
      left: 1%;
      width: 27px;
      height: 27px;
      bottom: -27px;
      animation-delay: 195s;
    }
    .background li:nth-child(42) {
      left: 38%;
      width: 23px;
      height: 23px;
      bottom: -23px;
      animation-delay: 38s;
    }
    .background li:nth-child(43) {
      left: 18%;
      width: 5px;
      height: 5px;
      bottom: -5px;
      animation-delay: 92s;
    }
    .background li:nth-child(44) {
      left: 2%;
      width: 18px;
      height: 18px;
      bottom: -18px;
      animation-delay: 217s;
    }
    .background li:nth-child(45) {
      left: 35%;
      width: 11px;
      height: 11px;
      bottom: -11px;
      animation-delay: 31s;
    }
    .background li:nth-child(46) {
      left: 58%;
      width: 22px;
      height: 22px;
      bottom: -22px;
      animation-delay: 43s;
    }
    .background li:nth-child(47) {
      left: 34%;
      width: 27px;
      height: 27px;
      bottom: -27px;
      animation-delay: 105s;
    }
    .background li:nth-child(48) {
      left: 73%;
      width: 11px;
      height: 11px;
      bottom: -11px;
      animation-delay: 56s;
    }
    .background li:nth-child(49) {
      left: 38%;
      width: 22px;
      height: 22px;
      bottom: -22px;
      animation-delay: 156s;
    }
  }
  /** END Event detail **/