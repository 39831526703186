* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

$logoPath: "./assets/img/logo.svg";
$logoColorPath: "./assets/img/logo-color.svg";
$logoPathMobile: "./assets/img/logo.svg";
$iconBtnAdd: "./assets/img/create.svg";

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;
$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-1: #224082;
$theme-color-2: #3498ff;
$theme-color-3: #3498ff8c;
$theme-color-4: #2296f3;
$theme-color-6: #dfe4ef;
$theme-color-imgage-2: linear-gradient(90deg, #6384d3, #365293);
$foreground-color: white;
$primary-color: #212121;
$secondary-color: #8f8f8f;
$button-text-color: #fff;
$input-background: white;

$toolbar-height: 65px;
$drawer-width: 300px;
$font-size-normal: 1.2rem;
$font-size-mini: 1rem;

//Responsive Breakpoints

$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px
);

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint); // Write the media query.
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@import "checkin";
@import "nav";
@import "layout";
@import "chat";

@font-face {
  font-family: "UTMHelve";
  src: url("./assets/fonts/utm-helve.ttf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/** Body Overview**/
#app-container {
  flex: 1 0 auto;
  position: relative;

  a {
    text-decoration: none !important;
  }
}
.body-overview {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.min-h-500 {
  min-height: 500px;
}
/** End Body Overview**/
body {
  height: 100%;
}

body {
  font-family: UTMHelve, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #212121;
  // background: #bdc3c6;
  box-sizing: border-box;
  overflow-x: hidden;
  background-image: linear-gradient(to right top, #e4f3ff, #edf5ff, #f5f8ff, #fbfbff, #ffffff);
  margin: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.text-center {
  text-align: center;
}

.icon-add {
  width: 30px;
  height: 30px;
  background: url($iconBtnAdd) no-repeat;
  background-position: center center;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: $theme-color-1;
  }
}

.rs-loader-vertical .rs-loader-spin {
  z-index: 1;
}
/**
QRCode
*/

.qrcode-product-detail {
  height: 100vh;

  .btn-submit {
    margin-bottom: 10px;
  }
  
  .full-height {
    height: 100% !important;
  }

  .qrcode-product-detail__img-wraper {
    width: 100%;
    text-align: center;
    // max-height: 350px;
    // height: 350px;

    .qrcode-product-detail__img {


      img {
        max-width: 100%;
        display: block;
        object-fit: contain;
        margin: 0 auto;
        max-height: 250px;
      }
    }
  }

  .qrcode-product-detail__title {
    font-size: 23px;
    font-family: "UTMHelve", sans-serif;
  }

  .qrcode-product-detail__note {
    padding: 5px;
    li {
      list-style-type: none;
      font-size: 15px;
      padding: 12px 0px;

      span {
        margin: 0px 5px;
      }

      span:nth-child(1) {
        color: $theme-color-1;
      }

      span:nth-child(2) {
        font-size: 14px;
        font-weight: 600;
      }

      span:nth-child(3) {
        float: right;
      }
    }
    .qrcode-product-detail__list-note {
      margin: 5px 0px;
    }
  }
  .qrcode-product-detail__list {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      font-size: 15px;
      padding: 10px 0px;

      span {
        margin: 0px 5px;
      }

      span:nth-child(1) {
        color: $theme-color-1;
      }

      span:nth-child(2) {
        font-size: 14px;
        font-weight: 600;
      }

      span:nth-child(3) {
        float: right;
      }
    }

    .select-up {
      margin-top: 10px;
    }

    .qrcode-product-detail__list-note {
      margin: 5px 0px;
    }
  }
}
/**
End QRCode
*/

/** Accounts **/
.accounts-online {
  width: 100%;
  // width: 320px;
  height: 184px;
  background-color: #34456b;
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: end;
  padding: 20px;
  border-radius: 4px;

  div {
    margin: 0 auto;

    h1 {
      color: #fafcff;
      font-size: 62px;
    }
    p {
      font-size: 18px;
      color: #f4f6f9;
    }
  }

  span::before {
    content: "";
    width: 67px;
    height: 67px;
    background: url("./assets/img/account-live-icon.svg") top left no-repeat;
    background-size: 105px;
    background-position: 10px;
    padding: 50px 59px;
    display: initial;
  }
}
/** END Accounts **/

/** Event **/
.time-color {
  color: #0d55f7;
  font-weight: 600;
}
.group-btn-grid {
  display: grid;

  button {
    margin: 5px 0px;
  }
}
.event_account_manager__icon {
  background: url("./assets/img/event-icon.svg") top left no-repeat;
  background-size: 14px;
  background-position: 12px 2px;
  padding: 0px 20px;
}
.event-add-container {
  display: block;
  text-align: end;
  padding: 0px 25px;

  .event-add__text {
    display: inline-flex;
    // padding: 10px 10px;
    padding-right: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 17px;
  }

  .live {
    &:hover {
      color: #f9f9f9;
      font-weight: 600;
    }
  }

  .event-add__icon {
    vertical-align: middle;
    margin-bottom: 5px;
    cursor: pointer;
    color: $primary-color;
  }
}

.data-passion-border {
  border-bottom: 5px solid #224082;
  border-radius: 2px;
}
.data-current-border {
  border-bottom: 5px solid #224082;
  border-radius: 2px;
}
.data-feature-border {
  border-bottom: 5px solid #224082;
  border-radius: 2px;
}
.account-container {
  cursor: pointer;

  .account-container__avatar {
    border-radius: 50%;
    border: 1px solid #efefef;
    width: 25px;
    height: 22px;
    padding: 11px 12px;
    margin-left: 5px;

    img {
      width: 15px;
      height: 15px;
    }
  }
  .account-container__more {
    border-radius: 50%;
    border: 1px solid #efefef;
    width: 25px;
    height: 22px;
    padding: 11px 12px;
    margin-left: 5px;
  }
  .account-container__add {
    border-radius: 50%;
    border: 1px solid #efefef;
    width: 25px;
    height: 22px;
    padding: 11px 15px;
  }
}
.scroll {
  max-height: 350px;
  height: 350px;
}
.full-width-modal-header {
  h5 {
    width: 100% !important;
  }
}
.modal-header-toolbar__search {
  display: inline-flex;
  float: right;

  label {
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 10px;
    bottom: 0;
    width: 15px;
    background: url("./assets/img/search.svg") top left no-repeat;
  }

  input {
    border-radius: 17px;
    font-size: 15px;
  }
}
.w-table-parent {
  width: 100%;
  display: table;
}
.w-table-child {
  min-width: 200px;
  display: table-cell;
  width: auto;
  text-align: center;
}

/** End Event **/

/** Dashboard **/
.dashboard-container__color {
  margin: 10px 0px;
  max-height: 140px;
  height: 140px;

  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .dashboard__count {
    color: #f2f5fc;
    font-size: 52px;
    padding: 15px;
  }

  .dashboard__count--color--dark {
    color: #393b3d;
    font-size: 52px;
  }

  .dashboard__sub--color--dark {
    color: #474647;
    font-size: 14px;
  }

  p {
    color: #f9f7f8;
    font-size: 14px;
  }

  &--green {
    background-color: #71c1b4;

    .dashboard-container__icon {
      background: url("./assets/img/dashboard-icon-total.svg") top left no-repeat;
      background-size: 77px 53px;
      background-position: 0 0;
      padding: 29px 45px;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 30px;
    }
  }
  &--green-blur {
    background-color: #b5ddc2;

    .dashboard-container__icon {
      background: url("./assets/img/dashboard-icon-out.svg") top left no-repeat;
      background-size: 77px 73px;
      background-position: 0 0;
      padding: 37px 45px;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 10px;
    }
  }
  &--yellow {
    background-color: #f5ffbe;

    .dashboard-container__icon {
      background: url("./assets/img/dashboard-icon-instock.svg") top left no-repeat;
      background-size: 77px 75px;
      background-position: 0 0;
      padding: 38px 45px;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 7px;
    }
  }
  &--red {
    background-color: #ed557e;

    .dashboard-container__icon {
      background: url("./assets/img/dashboard-icon-outofdate.svg") top left no-repeat;
      background-size: 77px 62px;
      background-position: 0 0;
      padding: 35px 45px;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 25px;
    }
  }
}
/** End Dashboard **/

/** Card **/
.card {
  width: auto;
  height: 370px;
  background-color: $foreground-color;
  margin-bottom: 24px;
  border-radius: 12px;
  transition: all 0.25s ease;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

  &--mini {
    min-width: 250px;
  }
  &--lg {
    // height: 520px;
    height: 600px;
  }
  &--medium-lg {
    height: 600px;
  }
  &--super-lg {
    height: 900px;
  }
  &--title {
    font-size: 16px;
    font-weight: 600;
    font-family: Lato, sans-serif;
    color: #344b60;
    margin-bottom: 10px;
  }
  &--title-right {
    position: absolute;
    right: 25px;
  }
  &--head-inline {
    display: inline-flex;
    align-items: center;

    p {
      margin-right: 25px;
    }
  }
  &--subtitle {
    margin: 10px 0px;

    & > * {
      margin: 0px 5px;
    }
  }

  &-super-mini {
    margin: 0 auto;
    height: unset;
    max-width: 480px;
    min-width: 150px;
    background-color: $foreground-color;
    margin-bottom: 24px;
    border-radius: 12px;
    transition: all 0.25s ease;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

    p {
      font-size: 40px;
      font-weight: bold;
    }

    q {
      font-size: 14px;
      font-weight: bold;
    }

    .rs-divider-with-text {
      margin: 18px 0px !important;
    }
  }
}

.card__search {
  padding: 5px;
  background-color: #5ba4d9;
  color: #f9f9f9;
  height: 58px;
  padding-left: 21px;
  position: relative;

  &-input {
    label {
      position: relative;
      top: 5px;
    }

    label:after {
      content: "";
      position: absolute;
      right: 11px;
      top: 9px;
      bottom: 0;
      width: 14px;
      background: url("./assets/img/search-internal.svg") top left no-repeat;
      color: #f4f6f9;
    }

    input {
      border-radius: 14px;
      font-size: 13px;
      width: 300px;
      background-color: rgba(157, 199, 230, 0.5);
      border: unset;
      color: #f9f9f9;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #f4f6f9;
        // opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #f4f6f9;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #f4f6f9;
      }
    }
  }

  &-container {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .card-title-with-search__title {
    text-transform: uppercase;
    font-size: 26px;
  }
}
/** End Card **/

/**
Login Form
*/
input.login-container__username::placeholder,
input.login-container__password::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}
.login-container {
  margin: 0 auto;
  background-color: $theme-color-1;
  display: table;
  width: 100%;
  height: 100vh;

  .login-container__language {
    float: right;
  }

  .login-container__form {
    position: absolute;
    width: 350px;
    height: 480px;
    background-color: rgba(248, 247, 249, 0.2);
    top: 50%;
    left: 50%;
    // margin-top: -250px;
    // margin-left: -150px;
    padding: 10px 40px;
    border-radius: 30px;
    transform: translate(-50%, -50%);

    .side-drawer-logo-container {
      text-align: center;
      margin: 30px;
      margin-top: 70px;

      .side-drawer-account-container {
        min-height: 100px;
        text-align: center;
        position: relative;

        .side-drawer-accounts__avatar {
          position: absolute;
          left: 0;
          top: 0;

          img {
            width: 72px !important;
            height: 72px !important;
            border-radius: 50%;
          }
        }
        .side-drawer-accounts__name {
          color: #fff;
          position: absolute;
          right: 0px !important;
        }
      }
    }

    .side-drawer-logo {
      width: 110px;
      height: 100px;
      background: url($logoPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
    }

    .login-container__input {
      font-size: 16px;
      color: #f9f9f9;

      .login-container__username {
        font-size: 18px;
        padding: 20px;
        margin-bottom: 15px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-username.svg") top left no-repeat;
        background-size: 23px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }
      .login-container__password {
        margin-top: 15px;
        font-size: 18px;
        padding: 20px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-password.svg") top left no-repeat;
        background-size: 21px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }

      .login-container__checkbox-wraper {
        padding: 5px 0px 20px 0px;

        .login-container__checkbox {
          font-size: 15px;
          user-select: none;
          margin-left: -5px;

          label:before {
            background-color: rgba(78, 143, 255, 0.8);
          }
        }
      }
    }

    .login-container__btn-wraper {
      text-align: center;
      .login-container__btn {
        margin: 0 auto;
        background-color: rgba(78, 143, 255, 0.8);
        width: 100%;
        border: 1px solid rgba(78, 143, 255, 1);
        border-radius: 20px;
        font-size: 20px;
        color: #fff;

        &:hover {
          background-color: rgba(96, 154, 255, 0.8);
          color: #f9f9f9;
        }
      }
    }
  }
}
/** End Login **/

.h-100vh {
  min-height: calc(100vh - 110px) !important;
}

.container__title,
.container__title-outside {
  text-transform: uppercase;
  font-size: 26px;
  color: #19274f;
  // margin: 20px 0px 5px 0px;

  h1 {
    font-weight: 900;
    font-size: 1.25rem;
    // padding-bottom: 10px;
    display: inline-block;
  }
}

/**
Layout
**/
.custom-nav-item {
  &:hover {
    background-color: rgba(221, 226, 255, 0.1);
  }
}
.side-drawer {
  // height: -webkit-fill-available;;
  height: 100%;
  // background: #224082;
  background-image: linear-gradient(to right, #6384d3, #365293);
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  // max-width: 400px;
  z-index: 200;
  transform: translateX(0%);
  transition: transform 0.3s ease-out;
  display: block;
  overflow: scroll;
  position: fixed;

  &-logo-container {
    text-align: center;
    margin: 30px;
    margin-top: 70px;

    .side-drawer-account-container {
      min-height: 100px;
      text-align: center;
      position: relative;

      .side-drawer-accounts__avatar {
        position: absolute;
        left: 0;
        top: 0;

        img {
          width: 72px !important;
          height: 72px !important;
          border-radius: 50%;
        }
      }
      .side-drawer-accounts__name {
        color: #fff;
        position: absolute;
        right: 0px !important;
      }
    }
  }
  &-logo {
    width: 90px;
    height: 40px;
    background: url($logoColorPath) no-repeat;
    background-position: center center;
    display: inline-block;
    margin: 0 auto;
    margin: 0px 10px;
    cursor: pointer;
  }
  &.open {
    transform: translateX(0);
  }

  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(221, 226, 255, 0.1);
    margin: 0 !important;
  }

  li {
    // margin: 0.5rem 0;
    // border-bottom: 1px solid rgba(119, 119, 119, 0.3);
    width: 100%;
  }
  a {
    text-decoration: none;
    font-size: 1.2rem;
  }
  a:hover,
  a:active {
    color: #fa923f;
  }
}

.toolbar {
  // position: fixed;
  // position: absolute;
  top: 0;
  /* left: 0; */
  /* width: 100%; */
  right: 0;
  width: 100%;
  background: #fff;
  height: $toolbar-height;
  z-index: 999;
  box-shadow: 0px 0px 3px #ccc;

  &__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;

    .toolbar__search {
      // margin-left: 1rem;
      margin-top: 8px;

      span {
        margin-right: 8px;
        font-size: 15px;
      }

      div {
        position: relative;
        margin-top: 15px;

        span::after {
          content: "";
          position: absolute;
          right: 15px;
          top: 10px;
          bottom: 0;
          width: 20px;
          background: url("./assets/img/search.svg") top left no-repeat;
          cursor: pointer;
        }
      }

      input {
        border-radius: 20px;
        font-size: 17px;
      }
    }

    .toolbar__qr {
      width: 40px;
      height: 40px;
      position: relative;
      margin: 0px 15px;

      .toolbar__qr__icon {
        background: url("./assets/img/qrcode.svg") top left no-repeat;
        width: 40px;
        height: 40px;
        position: absolute;
      }
    }
  }

  &_navigation-items {
    cursor: pointer;

    .toolbar_navigation-items__setting {
      background: url("./assets/img/setting-icon.svg") top left no-repeat;
      width: 35px;
      height: 35px;
      padding: 15px;
      padding-top: 5px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      font-size: $font-size-normal;;
    }
    li {
      padding: 0 0.5rem;
    }
    a {
      color: #070707;
      text-decoration: none;
    }
    a:hover,
    a:active {
      color: #fa923f;
    }
  }
}

.spacer {
  flex: 1;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  color: #070707;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
  &__line {
    width: 30px;
    height: 2px;
    background: #070707;
  }
}

.is-mobile {
  display: block;
}
.is-desktop {
  display: none;
}

.notfound {
  text-align: center;
  background-color: unset;
  font-weight: 400;
  font-size: 34px;
  line-height: 15px;
  letter-spacing: 2px;
}

.main-content {
  margin: 0 auto;

  .marquee-container {
    padding: 10px;
    font-size: 15px;
  }

  .rs-col {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 0;
    margin-right: 0;
  }
}
/** End layout */

/** Sidebar menu */
.nav-item-icon {
  &-products {
    background: url("./assets/img/menu-icon-product.svg") top left no-repeat;
    width: 20px;
    height: 20px;
  }
  &-dashboards {
    background: url("./assets/img/menu-icon-dashboard.svg") top left no-repeat;
    width: 20px;
    height: 20px;
  }
  &-accounts {
    background: url("./assets/img/menu-icon-account.svg") top left no-repeat;
    width: 20px;
    height: 20px;
  }
  &-events {
    background: url("./assets/img/menu-icon-event.svg") top left no-repeat;
    width: 20px;
    height: 20px;
  }
  &-warehouses {
    background: url("./assets/img/menu-icon-warehouse.svg") top left no-repeat;
    width: 20px;
    height: 20px;
  }
  &-none {
    padding-left: 10px;
  }
}
.sibar-child-wraper {
  padding-left: 15px;
  width: 100%;

  .sidebar-dot-wraper {
    .sidebar-dot-icon {
      background: url("./assets/img/dot.svg") top left no-repeat;
      background-size: 5px;
      background-position: 0;
      padding-left: 15px;
    }
  }
  .sidebar-arrow-wraper {
    float: right;

    .sidebar-arrow-icon-left {
      background: url("./assets/img/sidebar-arrow-left.svg") top left no-repeat;
      background-size: 5px;
      background-position: 24px;
      padding-left: 31px;
    }

    .sidebar-arrow-icon-down {
      background: url("./assets/img/sidebar-arrow-down.svg") top left no-repeat;
      background-size: 10px;
      background-position: 15px;
      padding-left: 25px;
    }
  }
}
/**
End Sidebar menu
*/

/** Print **/
.print-subcontent {
  p {
    font-weight: 900;
    text-transform: uppercase;
  }
}
.fz34 {
  margin-top: 20px;
  p {
    font-size: 34px;
  }
}
.fz24 {
  margin-top: 10px;
  p {
    font-size: 24px;
  }
}
.fz14 {
  margin-top: 5px;
  p {
    font-size: 14px;
  }
}
.fz10 {
  margin-top: 5px;
  p {
    font-size: 10px;
  }
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.disable {
  opacity: 0.4;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.custom-line-bottom {
  border-bottom: 1px solid #d7d7d7;
}
.position-relative {
  position: relative !important;
}
.d-block {
  display: block !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.align-right {
  position: absolute;
  right: 0;
}
.pr-4, .px-4 {
  padding-right: 1.5rem!important;
}
.pl-1, .px-1 {
  padding-left: .25rem!important;
}
.align-self-center {
  -ms-flex-item-align: center!important;
  align-self: center!important;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.item-check {
  pointer-events: none;
}
.mb-0, .my-0 {
  margin-bottom: 0!important;
}
.icon-success {
  background: url("./assets/img/success.svg") top left no-repeat;
  background-size: 20px;
  background-position: 10px 2px;
  padding: 4px 20px;

  &:hover {
    cursor: pointer;
  }
}

.icon-error {
  background: url("./assets/img/error.svg") top left no-repeat;
  background-size: 20px;
  background-position: 10px 2px;
  padding: 4px 20px;

  &:hover {
    cursor: pointer;
  }
}

.modal-wrapper-img {
  width: auto;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.table-setting {
  background-color: #fff;

  .wrapper-img {
    width: 40px;
    height: 40px;
    margin-top: 2px;
    overflow: hidden;
    display: inline-block;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .radius {
    border-radius: 50%;
  }
}

@media print {
  /* print styles */
  body {
    margin: 0;
    color: #000;
    background-color: #fff;

    h1 {
      color: #000;
      background: none;
    }

    nav,
    aside {
      display: none;
    }

    .print__container {
      max-width: 500px;
      text-align: center;

      img {
        text-align: center;
      }
      .print-subcontent {
        p {
          font-weight: 900;
          text-transform: uppercase;
        }
      }
      .fz34 {
        margin-top: 20px;
        p {
          font-size: 34px;
        }
      }
      .fz24 {
        margin-top: 10px;
        p {
          font-size: 24px;
        }
      }
      .fz14 {
        margin-top: 5px;
        p {
          font-size: 14px;
        }
      }
      .fz10 {
        margin-top: 5px;
        p {
          font-size: 10px;
        }
      }
    }
  }
}
/** End Print **/

/** Nav **/
.menu-item {
  height: 45px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid rgba(119, 119, 119, .3);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .active-bar {
    position: 'absolute';
    left: 0;
  }

  .active-container {
    background-color: rgba(13,32,40, .2);
  }
  .menu-title {
    font-family: 'Muli';
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #ccc8ce;
  }
  .active-title {
    color: #f9f9f9;
    margin-left: 5px;
  }
}
.menu-item-collapsible {
  .rs-panel-body {
    padding: 0;
  }
}


.nav-tabs.separator-tabs {
  border-bottom: 1px solid $separator-color;
}

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  position: relative;

  &::before {
    // content: " ";
    // background: $theme-color-1;
    // border-radius: 10px;
    // position: absolute;
    // width: 100%;
    // height: 5px;
    // left: 0;
    // top: 0;
    content: " ";
    background: $theme-color-6;
    border-radius: 10px;
    position: absolute;
    width: calc(100% - 1rem);
    height: 5px;
    // top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  // background: $foreground-color;
  background: initial;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-link {
  display: block;
  padding: .5rem 1rem;
}
.nav-tabs .nav-link.active::before,
// .nav-link::before,
.nav-tabs .nav-item.show {
  // content: " ";
  // background: $theme-color-1;
  // border-radius: 10px;
  // position: absolute;
  // width: 100%;
  // height: 5px;
  // left: 0;
  // top: 0;
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: calc(100% - 1rem);
  height: 5px;
  // top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: $theme-color-1;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset;
  transform: initial;
}

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: $secondary-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: $theme-color-1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: $theme-color-1;
}
/** End Nav **/


.img-modal-thmb {
  max-width: 80px!important;
  height: 80px;
}

.img-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
}

.list-thumbnail {
  border-radius: $border-radius;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important;
  min-width: 80px;

  @include respond-below(md) {
    height: 80px;
  }

  @include respond-below(xs) {
    height: 70px;
  }

  &.responsive {
    @include respond-below(md) {
      width: unset;
      height: 136px;
    }

    @include respond-below(xs) {
      width: 90px !important;
      height: 90px !important;
      // width: 110px !important;
      // height: 100%;
    }

    @include respond-below(xxs) {
      width: 90px !important;
      // height: 100%;
      height: 90px !important;
    }
  }

  &.small {
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 55px;
    }

    @include respond-below(xs) {
      height: 50px;
    }
  }

  &.xsmall {
    height: 40px;
    font-size: 1rem;

    @include respond-below(md) {
      height: 40px;
    }

    @include respond-below(xs) {
      height: 40px;
    }
  }
}

.list-thumbnail-container {
  width: 100px;
  height: 100px;
  text-align: center;
}
.list-thumbnail-img {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain !important;
}

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: $theme-color-1;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: $button-text-color !important;

  @include respond-below(md) {
    width: 80px;
    height: 80px;
  }

  @include respond-below(xs) {
    width: 70px;
    height: 70px;
  }

  &.small {
    width: 60px;
    height: 60px;
    font-size: 1rem;

    @include respond-below(md) {
      width: 55px;
      height: 55px;
    }

    @include respond-below(xs) {
      width: 50px;
      height: 50px;
    }
  }
}


/** DropZone **/
.dropzone {
  min-height: 115px !important;
  border: 1px solid $separator-color !important;
  background: $input-background !important;
  padding: 10px 10px !important;
  border-radius: $border-radius !important;
  color: $primary-color !important;
  height: auto !important;

  .img-thumbnail {
    height: 58px !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid $separator-color !important;
  border-radius: $border-radius !important;
  background: $foreground-color !important;
  color: $primary-color !important;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: $theme-color-1;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: $theme-color-1 !important;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: $primary-color !important;
  }

  .dz-error-mark {
    color: $button-text-color !important;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:"+encodecolor(
          $theme-color-1
        )+";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:"+encodecolor(
          $foreground-color
        )+";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:"+encodecolor($foreground-color)+";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-success-mark {
    color: $button-text-color;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;

    span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:"+encodecolor(
          $theme-color-1
        )+";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:"+encodecolor(
          $foreground-color
        )+";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px;
    }
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
      background: $theme-color-1 !important;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color !important;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color !important;
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
    color: $theme-color-1 !important;
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}
/** End DropZone **/

@media (max-width: 512px) {
  .card-title-with-search {
    .card-title-with-search__search {
      input {
        width: 120px;
      }
    }
  }
  .event-add-container {
    .event-add__text {
      font-size: 15px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .w-table-child {
    min-width: 100px;
  }
  .accounts-online {
    h1 {
      font-size: 64px;
    }
    p {
      font-size: 21px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .accounts-online {
    h1 {
      font-size: 50px;
    }
    p {
      font-size: 15px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 1199px) {
  .side-drawer {
    transform: translateX(-100%);
    width: 250px;

    .side-drawer-accounts__name {
      top: 5px;
      p:nth-child(1) {
        font-size: 12px;
      }
      p:nth-child(2) {
        font-size: 10px;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .toolbar__toggle-button {
    display: none;
  }

  .main-content {
    max-width: 1200px;
  }

  .side-drawer {
    width: $drawer-width;
    // height: 100vh;

    .side-drawer-accounts__name {
      right: 20px !important;
      top: 25px;

      p:nth-child(1) {
        font-size: 16px;
        font-weight: 500;
        line-height: 0px;
      }
      p:nth-child(2) {
        font-size: 14px;
      }
    }
  }
  .toolbar__search {
    margin-left: $drawer-width;
  }

  .is-mobile {
    display: none;
  }
  .is-desktop {
    display: block;
  }
}


