$bgPath: "assets/img/checkin/oasis.jpg";

.checkin__container {
    margin: 0 auto;
    display: table;
    width: 100%;
    height: 100vh;

    background: url($bgPath) no-repeat;
    background-position: top left;
    background-size: cover;
    background-position: top left;

    &__form {
        h6 {
            text-align: center;
            padding: 10px 0px;
        }

        position: absolute;
        width: 350px;
        height: 480px;
        background-color: rgba(255, 255, 255, 1);
        top: 50%;
        left: 50%;
        padding: 20px 40px;
        border-radius: 30px;
        transform: translate(-50%, -50%);
    }
}
