.common__container {
    margin: 0px 15px;
    max-width: 1200px;

    .rs-btn {
        font-size: $font-size-normal;
    }

    .rs-table-cell-header .rs-table-cell-content {
        font-size: $font-size-mini;
    }

    .rs-input-group-md.rs-input-group-inside > .rs-input,
    .rs-input-group.rs-input-group-inside .rs-input {
        padding: 10px;
        font-size: $font-size-mini;
    }

    .rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn {
        padding: 10px;
    }

    &--list {
        min-height: 250px;
        margin-bottom: 24px;
        background-color: $foreground-color;
    }

    &--heading {
        // margin: 24px 0px;

        & > div {
            margin: 10px 0px;
        }
    }

    &--total-count {
        text-transform: uppercase;
        text-align: center;
    }

    &--search {
        margin: 10px 0px;

        input {
            padding: 10px;
            font-size: $font-size-mini;
        }
    }
}

/** Responsive **/
@media (max-width: 479px) {
    .common__container {
        margin: 0;
    }
}

@media (min-width: 480px) and (max-width: 575px) {
    .common__container {
        margin: 0;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
    .common__container {
        margin: 0;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .common__container {
        margin: 0 auto;
    }
}
