.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #eeeef1;
  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;

  .bold {
    font-weight: bold !important;
  }

  input {
    outline: none;
  }

  .container {
    padding: 10px;
  }

  .scrollable {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
  }

  .content {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
  }

  .footer {
    grid-column-start: 2;
    background: white;
  }

  .message-list-container {
    padding: 10px;
    min-height: 500px;
    height: clac(100vh);
    height: 100vh;

    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .conversation-list {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
  }

  .conversation-info {
    margin-bottom: 20px;
  }

  .toolbar-button {
    color: #007aff;
    font-size: 18px;
    transition: all 0.1s;
  }

  .toolbar-button:hover {
    cursor: pointer;
    color: #0063ce;
  }

  .toolbar-button:active {
    color: #007aff;
    opacity: 0.25;
  }

  .toolbar {
    display: flex;
    align-items: center;

    background-color: white;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;

    position: sticky;
    top: 0px;
  }

  @supports (backdrop-filter: blur(20px)) {
    .toolbar {
      border: none;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(20px);
    }
  }

  .toolbar-title {
    margin: 0;
    font-size: 16px;
    font-weight: 800;
  }

  .left-items,
  .right-items,
  .send-right-items {
    flex: 1;
    padding: 10px;
    display: flex;
  }
  .send-right-items {
    i {
      margin: 0 20px;
    }
  }

  .right-items,
  .send-right-items {
    flex-direction: row-reverse;
  }

  .left-items .toolbar-button {
    margin-right: 20px;
  }

  .right-items .toolbar-button {
    margin-left: 20px;
  }

  .left-items .toolbar-button:last-child,
  .right-items .toolbar-button:last-child {
    margin: 0;
  }

  .conversation-search {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    .rs-input {
      width: unset;
    }
  }

  .conversation-search-input {
    background: #f4f4f8;
    padding: 8px 10px;
    border-radius: 10px;
    border: none;
    font-size: 14px;
  }

  .conversation-search-input::placeholder {
    text-align: center;
  }

  .conversation-search-input:focus::placeholder {
    text-align: left;
  }

  .conversation-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
  }

  .conversation-list-item:hover {
    background: #eeeef1;
    cursor: pointer;
  }

  .conversation-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

  .conversation-title {
    font-size: 14px;
    font-weight: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .conversation-snippet {
    font-size: 14px;
    // color: #888;
    margin: 0;
    font-weight: normal;
  }

  .compose {
    padding: 10px;
    display: flex;
    align-items: center;
    background: white;
    position: sticky;
    width: calc(100%);
    bottom: 0px;
    border-top: 1px solid #ececec;
    overflow: scroll;
  }

  @supports (backdrop-filter: blur(20px)) {
    .compose {
      border: none;
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      border-top: 1px solid #ececec;
    }
  }

  .compose-input {
    flex: 1;
    border: none;
    font-size: $font-size-mini;
    height: 40px;
    background: none;
  }

  .compose-input::placeholder {
    opacity: 0.3;
  }

  .compose .toolbar-button {
    color: #bbbbbf;
    margin-left: 15px;
  }

  .compose .toolbar-button:hover {
    color: #99999c;
  }

  .load-more {
    display: flex;
    justify-content: center;
    color: #999;
    font-weight: 600;
    font-size: 12px;
    margin: 10px 0px;
  }

  .message {
    display: flex;
    flex-direction: column;

    .bubble-media {
      max-width: 250px;
      max-height: 250px;
    }
  }

  .message .timestamp {
    display: flex;
    justify-content: center;
    color: #999;
    font-weight: 600;
    font-size: 12px;
    margin: 10px 0px;
    text-transform: uppercase;
  }

  .message .bubble-container {
    font-size: 14px;
    display: flex;
  }

  .bubble-title {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: normal;
    line-height: 1.28;
    margin-bottom: 1px;
    overflow: hidden;
    padding-left: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .message.mine .bubble-container {
    justify-content: flex-end;
  }

  // .message.start .bubble-container .bubble {
  //   /* margin-top: 10px; */
  //   border-top-left-radius: 20px;
  // }

  // .message.end .bubble-container .bubble {
  //   border-bottom-left-radius: 20px;
  //   /* margin-bottom: 10px; */
  // }

  // .message.mine.start .bubble-container .bubble {
  //   margin-top: 10px;
  //   border-top-right-radius: 20px;
  // }

  // .message.mine.end .bubble-container .bubble {
  //   border-bottom-right-radius: 20px;
  //   margin-bottom: 10px;
  // }

  .message.start .bubble-container .bubble {
    border-bottom-left-radius: 20px;
  }

  .message.end .bubble-container .bubble {
    border-top-left-radius: 20px;
  }

  .message.mine.start .bubble-container .bubble {
    border-bottom-right-radius: 20px;
    margin-bottom: 10px;
  }

  .message.mine.end .bubble-container .bubble {
    margin-top: 10px;
    border-top-right-radius: 20px;
  }

  .message .bubble-container .bubble {
    margin: 1px 0px;
    background: #f4f4f8;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 75%;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    white-space: normal;
    word-wrap: break-word;
  }

  .message.mine .bubble-container .bubble {
    background: #007aff;
    color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

@media (max-width: 650px) {
  .messenger {
    display: grid;
    width: 100%;
    height: 100vh;
    background: #eeeef1;
    grid-template-columns: 250px auto;
    grid-template-rows: 60px auto 60px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;

    .send-right-items {
      flex: 1.5;
      i {
        margin: 0 5px;
      }
    }
  }
}
